import React from 'react'
import styled from '@emotion/styled'

const Phone = props => {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.67681 0H3.32261C2.92401 0 2.59961 0.324398 2.59961 0.723V11.277C2.59961 11.6756 2.92401 12 3.32261 12H8.6766C9.0752 12 9.3996 11.6756 9.3996 11.2772V0.723C9.39962 0.324398 9.07523 0 8.67681 0ZM8.99962 11.277C8.99962 11.455 8.85482 11.6 8.67681 11.6H3.32261C3.14441 11.6 2.99962 11.455 2.99962 11.2772V0.723C2.99962 0.544992 3.14441 0.400008 3.32261 0.400008H8.6766C8.8548 0.400008 8.99959 0.545016 8.99959 0.723V11.277H8.99962Z" />
      <path d="M9.19959 1.19995H2.7996C2.68921 1.19995 2.59961 1.28955 2.59961 1.39994V9.79994C2.59961 9.91033 2.68921 9.99994 2.7996 9.99994H9.19961C9.31 9.99994 9.3996 9.91033 9.3996 9.79994V1.39994C9.3996 1.28955 9.31 1.19995 9.19959 1.19995ZM8.99959 9.59995H2.99959V1.59996H8.99959V9.59995Z" />
    </svg>
  )
}

export default Phone
