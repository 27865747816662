import React from 'react'
import styled from '@emotion/styled'

const Map = props => {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M5.99901 0C2.801 0 0.73623 3.38897 2.17114 6.22287L4.37358 10.9629C5.01652 12.3467 6.98248 12.3447 7.62448 10.9629L9.82694 6.22283C11.2613 3.39005 9.19847 0 5.99901 0V0ZM9.15164 5.88718C9.14721 5.89585 9.25135 5.67203 6.94063 10.6451C6.56763 11.4478 5.4308 11.4485 5.05745 10.6451C2.75545 5.69078 2.85105 5.89628 2.84648 5.88723C1.67018 3.57316 3.34124 0.754099 5.99901 0.754099C8.65528 0.754099 10.3287 3.57132 9.15164 5.88718Z" />
        <path d="M5.99934 2.19312C4.84414 2.19312 3.9043 3.13295 3.9043 4.28816C3.9043 5.44336 4.84414 6.3832 5.99934 6.3832C7.15455 6.3832 8.09439 5.44336 8.09439 4.28816C8.09439 3.13295 7.15457 2.19312 5.99934 2.19312ZM5.99934 5.62913C5.25993 5.62913 4.65839 5.0276 4.65839 4.28818C4.65839 3.54877 5.25993 2.94724 5.99934 2.94724C6.73876 2.94724 7.34029 3.54877 7.34029 4.28818C7.34029 5.0276 6.73876 5.62913 5.99934 5.62913Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Map
